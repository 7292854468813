<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse tw-p-8"
  >
    <div>
      <div>
        <img
          src="@/assets/svgs/create-a-trip/calendar-icon.svg"
          alt="Airplane"
          class="tw-mx-auto tw-mb-4"
        />
        <h2
          class="tw-text-lg tw-font-semibold tw-text-dark-green tw-w-4/6 tw-mx-auto tw-leading-8 tw-mb-10"
        >
          Yay, now to invite your crew!
        </h2>
        <p
          class="tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22 tw-w-4/6 tw-mx-auto"
        >
          Before invites, <strong>would you like to attach a survey</strong> to
          ask about availability & travel preferences?
        </p>
        <v-btn
          rounded
          block
          color="secondary"
          x-large
          class="tw-mt-40 tw-mb-6 tw-tracking-normal"
          @click="openSurveyRoute()"
          >Yes, Create Survey</v-btn
        >
        <router-link
          class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
          :to="{ name: 'CreateInvitationForm', params: { id: trip.id } }"
        >
          Skip and go to Invites
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
  },
  methods: {
    openSurveyRoute() {
      sessionStorage.setItem("clicked_survey_creation", this.$route.path);
      this.$router.push({
        name: "SurveyCreateRouteForm",
        params: { id: this.$route.params.id },
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("meta/fireConfetti");
  },
};
</script>
